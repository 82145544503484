import {mapActions} from 'vuex';

export default {
	data: function () {
		return {
			data: undefined,
			timestamp: undefined
		};
	},
	metaInfo() {
		return {
			title: `AUTO.RIA™ — ${this.langId === 2 ? 'Проверка авто по реестру движимого имущества' : 'Перевірка авто за реєстром рухомого майна'}`,
			meta: [
				{name: 'description', content: this.langId === 2 ? 'Узнайте сведения об авто из реестра движимого имущества' : 'Дізнайтеся відомості про авто з реєстру рухомого майна'},
				{name: 'keywords', content: ''},
				{property: 'og:title', content: ''},
				{property: 'og:description', content: ''},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'},
				{
					rel: 'stylesheet',
					media: 'screen',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto.css?v=1.01'
				},
				{
					rel: 'stylesheet',
					media: 'print',
					type: 'text/css',
					href: 'https://css.riastatic.com/stylesheets/checklist_sto-print.css?v=1.01'
				},
			]
		};
	},
	methods: {
		...mapActions({
			fetchNAISReport: 'others/autotest/nais'
		})
	},
	mounted() {
		this.fetchNAISReport({
			plateNumber: this.$route.params.plateNumber,
			hash: this.$route.query.hash,
			autoId: this.$route.query.aid,
		}).then((data) => {
			this.data = data;
		});
	}
};
